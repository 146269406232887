class DownloadFile {
  download(fileName, source, type) {
    const element = document.createElement('a');
    element.setAttribute('href', source);
    if (type === 'otherList') {
      element.setAttribute('target', '_blank');
    }
    element.setAttribute('download', fileName);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
}

export default DownloadFile;
