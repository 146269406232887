<template>
  <div id="divFirstLoginWrapper">
    <VueForm ref="form">
      <VueText sizeLevel="11" weightLevel="3" class="header" color="grey-40"
        >İlk Giriş/Şifremi Unuttum</VueText
      >
      <VueHeadline level="1" appearanceLevel="3" class="small-header"
        >Telefon numaranızı girip <b>"Devam Et"</b> butonuna tıkladıktan sonra telefonunuza mesaj
        (SMS) olarak doğrulama kodu iletilecektir. Mesaj bilgilerinde gönderilen doğrulama kodu ile
        sisteme giriş yapabilirsiniz.</VueHeadline
      >
      <VueText sizeLevel="5" weightLevel="3" class="form-text" color="grey-40"
        >Telefon Numaranız</VueText
      >
      <VueInput
        name="phoneNumber"
        mask="5#########"
        id="phoneNumber"
        type="tel"
        validatorName="Telefon Numarası"
        v-model="formModel.phoneNumber"
        :messageOnTop="true"
        :inputLarge="true"
        validationRules="required|numeric|max:10|min:10"
      />
      <div slot="footer" class="button-wrapper">
        <BrandButton
          class="submit-button"
          :size="sizes.xxLarge"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          @click="handleSubmit"
          >DEVAM ET</BrandButton
        >
        <BrandButton
          class="submit-button"
          :size="sizes.xxLarge"
          :outlined="true"
          :contentAlignment="constants.flexAlignment.center"
          type="submit"
          @click.prevent="redirectToLogin"
          >GERİ DÖN</BrandButton
        >
      </div>
    </VueForm>
    <BrandAuthFormFooter></BrandAuthFormFooter>
    <BrandModal></BrandModal>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Account } from '@/services/Api/index';
import BrandAuthFormFooter from '@/components/brand/BrandAuthFormFooter/BrandAuthFormFooter.vue';
import RoutesAthentication from '@/router/routes/RoutesAthentication.js';
import RoutesLogin from '@/router/routes/authentication/RoutesLogin.js';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import BrandModal from '@/components/brand/Modals/BrandModal/BrandModal.vue';
import VueForm from '@/components/shared/VueForm/VueForm.vue';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import COMPONENT_CONSTANTS from '@/constants/component.constants.js';
import VueInput from '@/components/shared/VueInput/VueInput.vue';
import StorageHelper from '@/utils/storageHelper';

export default {
  name: 'FirstLogin',
  components: {
    BrandAuthFormFooter,
    VueHeadline,
    VueForm,
    BrandButton,
    BrandModal,
    VueInput,
    VueText,
  },
  data() {
    return {
      formModel: {
        phoneNumber: '',
      },
      submitted: false,
    };
  },
  computed: {
    routes() {
      return {
        auth: RoutesAthentication,
        login: RoutesLogin,
      };
    },
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
    sizes() {
      return COMPONENT_CONSTANTS.COMPONENT_SIZES;
    },
  },
  methods: {
    redirectToLogin() {
      this.$router.go(-1);
    },
    async handleSubmit(e) {
      e.preventDefault();
      this.submitted = true;

      const isValid = await this.$refs.form.$refs.observer.validate();
      if (!isValid) {
        return;
      } else {
        const { phoneNumber } = this.formModel;

        Account.sendSmsForChangePassword(phoneNumber).then(res => {
          if (res.data.Data) {
            new StorageHelper({ id: process.env.VUE_APP_STORAGE_CURRENT_USER_PN_KEY }).set(
              this.formModel.phoneNumber,
            );
            new StorageHelper({ id: process.env.VUE_APP_STORAGE_FIRST_LOGIN_SMS_COUNTER }).set({
              date: new Date(),
              isRefresh: false,
            });
            this.$router.push('/auth/login/sms-verification');
          }
        });
      }
    },
  },
};
</script>

<style lang="scss">
.header {
  padding-top: palette-space-level('60');
}

.small-header {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

.form-text {
  padding-top: palette-space-level('20');
  padding-bottom: palette-space-level('10');
}

.button-wrapper {
  padding: palette-space-level('40') 0 palette-space-level('20');
  .submit-button {
    border-radius: palette-radius-level('2');
    font-weight: palette-font-weight-level('3');
    &:first-child {
      margin-bottom: palette-space-level('10');
    }
    &:last-child {
      span {
        color: palette-color('red-10');
      }
    }
  }
}
</style>
